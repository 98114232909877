import React from 'react';
import fondo from './../styles/img/fondo-respuesta.png';
import Footer from './Footer';

const Cvsubidook = () => {
  return (
    <div>
      {/* <nav id="gtco-header-navbar" className="navbar navbar-expand-lg py-4">
        <div className="container">
        </div>
      </nav> */}
      <div className="jumbotron d-flex align-items-center">
        <div className="container text-center">
          <h1 className="display-2 mb-4">¡Tu solicitud fue recibida!</h1>
          <p>
            Te informaremos por mail el estado del proceso de selección.<br/>¡Gracias por tu postulación!
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Cvsubidook;
