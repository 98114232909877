import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';


import Header from './components/Header';

import Cvsubidook from './components/Cvsubidook';




function App() {
  return (
    <Router>
      <Header />
      <Routes>
        {/* Rutas públicas */}
        <Route path="/" element={<Home />} />


        <Route path="/cvsubidook" element={<Cvsubidook />} />
        
        
        {/* Ruta por defecto */}
        <Route path="*" element={<Home />} />

      </Routes>
    </Router>
  );
}

export default App;
