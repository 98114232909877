import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

/* import logo from '../styles/img/logo2.png';  */


function Footer() {
  
  return (
    <footer class="mastfoot mb-3 bg-white py-4 border-top">
    <div class="inner container">
         <div class="row">
            <div class="col-md-6 d-flex align-items-center justify-content-md-start justify-content-center">
              <p class="mb-0"></p>
            </div>
            <div class="col-md-6">
            	{/* <nav class="nav nav-mastfoot justify-content-md-end justify-content-center">
	                <a class="nav-link" href="https://www.instagram.com/meetsuper/">
	                	<i class="icon-instagram"></i>
	                </a>
	                <a class="nav-link" href="https://www.linkedin.com/company/meet-super/mycompany/">
	                	<i class="icon-linkedin"></i>
	                </a>
	            </nav> */}
            </div>
          </div>
      </div>
    </footer>
  );
}

export default Footer;
