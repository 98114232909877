import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "./../styles/style.min.css";
import Footer from "./Footer"; 
/* import ReactQuill from "react-quill"; */
import 'react-quill/dist/quill.snow.css';
import React from 'react';


const SuperJobApplicationForm = () => {
  
  const navigate = useNavigate();

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [formCode, setFormCode] = useState("");

  // Generar el código único al cargar la página
  useEffect(() => {
    const code = generateFormCode();
    setFormCode(code);
  }, []);
  
  const opciones = [
    "Director/a de cuentas",
    "Supervisor/a de cuentas",
    "Ejecutivo/a de cuentas",
    "Asistente de cuentas",
    "Diseñador/a gráfico",
    "Diseñador/a motion",
    "Head of design",
    "Director/a de arte",
    "Director/a creativo",
    "Redactor/a",
    "Head of social media",
    "Content strategist",
    "Community manager",
    "Content creator",
    "Project manager",
    "Planner estratégico/a",
    "Influencer marketing",
    "Director/a de medios",
    "Coordinador/a de medios",
    "Digital planner",
    "Planner off",
    "Planner integral ",
    "Account manager",
    "Analista paid media",
    "Programmatic specialist",
    "Head of data",
    "Data analyst",
    "Supervisor/a Creativo/a",
    "Head of Strategy",
    "Editor/a audiovisual"
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormSubmitted(true);

    const formData = new FormData(e.target);
    formData.append("codigo", formCode); 

    try {
      

      const response = await fetch("https://jobs.superlab.ai/api/enviarFormulario", {
        method: "POST",

        body: formData,
      });

      if (response.ok) {
        console.log("Formulario enviado exitosamente");
        // Obtener la respuesta como JSON directamente
        const jsonData = await response.json();
        // Puedes procesar jsonData según tus necesidades
        console.log(jsonData);
        // Redirigir al usuario a otra página (por ejemplo, "/exito") después de enviar el formulario
        navigate("/cvsubidook");
      } else {
        // Si la respuesta no es exitosa, obtener el mensaje de error del cuerpo de la respuesta
        const errorMessage = await response.text();
        // Mostrar el mensaje de error en un alert
        alert(errorMessage);
        console.error("Error al enviar formulario:", errorMessage);
        // Puedes manejar los errores según tus necesidades
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      // Puedes manejar los errores según tus necesidades
    }
  };


  // Función para generar un código único
  const generateFormCode = () => {
    const code = Math.random().toString(36).substring(7);
    return code;
  };

  return (
    <div style={{ minHeight: 800, marginTop: 5 }}>
      <div>
        <body
          data-spy="scroll"
          data-target="#navbar-nav-header"
          className="static-layout"
        >
          <div className="boxed-page">
            {/* Contact Form Section */}
            <section id="gtco-contact-form" className="bg-white rounded-top">
              {/* <img src={imageEmpresa} alt="Banner de la empresa" className="banner-img rounded-top" /> */}
              <div className="section-content">
                {/* Section Title */}
                <div className="title-wrap">
                  <h2 className="section-title">
                    Solicitud de empleo | SUPER{" "}
                  </h2>
                  <p className="section-sub-title">
                    Hola, en este formulario podrás aplicar a la búsqueda que
                    haga crecer tu desarrollo profesional <br />
                    Gracias por tu interés en Super, ojalá pronto empecemos a
                    trabajar juntos.
                    <br />
                  </p>
                </div>
                {/* End of Section Title */}
                <div className="row">
                  {/* Espacio izquierdo */}
                  <div className="col-md-2"></div>
                  {/* Contact Form Holder */}
                  <div className="col-md-8 mt-4">
                  {/* <div className="col-md-8 offset-md-2 mt-4"> */}
                    <form
                      action="/api/enviarFormulario"
                      method="post"
                      encType="multipart/form-data"
                      onSubmit={handleSubmit}
                    >
                      <div className="container">
                        <div className="row">
                        <div className="row">
                        <div className="col-md-6 form-group">
                          <label htmlFor="nombre">Nombre</label>
                          <input
                            type="text"
                            className="form-control"
                            id="nombre"
                            name="nombre"
                            required
                            placeholder="Ingrese su nombre"
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label htmlFor="apellido">Apellido</label>
                          <input
                            type="text"
                            className="form-control"
                            id="apellido"
                            name="apellido"
                            required
                            placeholder="Ingrese su apellido"
                          />
                        </div>
                      </div>
                          <div className="col-md-6 form-group">
                            <label htmlFor="puesto">Puesto a aplicar</label>
                            <select
                                className="custom-select form-control"
                                id="puesto"
                                name="puesto"
                                required
                              >
                                <option value="" disabled selected>Selecione un puesto
                                </option>
                                {opciones.map((opcion, index) => (
                                  <option key={index} value={opcion}>
                                    {opcion}
                                  </option>
                                ))}
                              </select>
                          </div>
                          {/* Nuevo campo: País de residencia */}
                          <div className="col-md-6 form-group">
                            <label htmlFor="pais">
                              País de Residencia
                            </label>
                            <select
                              className="custom-select form-control"
                              id="pais"
                              name="pais"
                              required
                              placeholder="Seleccione un puesto"
                            >
                              <option value="" disabled selected>
                                Seleccione su pais de residencia
                              </option>
                              <option value="EEUU">EEUU</option>
                              <option value="Argentina">Argentina</option>
                              <option value="Chile">Chile</option>
                              <option value="Perú">Perú</option>
                              <option value="México">México</option>
                              <option value="España">España</option>
                              <option value="Brasil">Brasil</option>
                              <option value="Uruguay">Uruguay</option>
                              <option value="Colombia">Colombia</option>
                              <option value="Ecuador">Ecuador</option>
                            </select>
                          </div>
                          <div className="col-md-6 form-group">
                            <label htmlFor="telefono">Teléfono</label>
                            <input
                              type="tel" // Utiliza el tipo "tel" que es específico para números telefónicos
                              className="form-control"
                              id="telefono"
                              name="telefono"
                              required
                              /* pattern="[0-9]{10}" */ // Un ejemplo de patrón para validar números de 10 dígitos
                              placeholder="Ingrese su código de área + número de telefono"
                            />
                          </div>
                          <div className="col-md-6 form-group">
                            <label htmlFor="email">Email</label>
                            <input
                              type="email"
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Mail"
                              required
                              autocomplete="email"
                            />
                          </div>
                          <div className="col-md-6 form-group">
                            <label htmlFor="pretension_salarial">
                            Indicar remuneración pretendida (en su moneda local) {" "}
                            </label>
                            <div className="input-group">
                              <input
                                type="number"
                                id="pretension_salarial"
                                name="pretension_salarial"
                                className="form-control"
                                placeholder="Ingrese el valor neto"
                                required
                              />
                              {/*<select
                                className="custom-select"
                                name="moneda"
                                required
                                style={{ height: "37px" }}
                              >
                                <option value="USD">USD</option>
                                <option value="ARS">ARS</option>
                                
                              </select>*/}
                            </div>
                          </div>
                          {/*el bloque de codigo estaba  aca*/}
                          <div className="col-12 form-group">
                            <label htmlFor="linkedin" translate="no">URL Linked-In</label>
                            <input
                              type="url"
                              id="linkedin"
                              name="linkedin"
                              className="form-control"
                              placeholder="URL Linked In"
                              required
                            />
                          </div>
                          <div className="col-12 form-group">
                            <label htmlFor="extra_url">
                              Portfolio
                              
                            </label>
                            <input
                              type="text"
                              id="extra_url"
                              name="extra_url"
                              className="form-control"
                              placeholder="Opcional"
                            />
                          </div>
                          
                          <div className="col-12 form-group">
                            <label className="input-group-text" htmlFor="cv">
                              Subir Curriculum
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="cv"
                              name="cv"
                              accept=".pdf, .doc, .docx"
                              required
                            />
                          </div>
                          <div className="col-12 espaciado-entre-cv-y-enviar"></div>
                          <div className="col-12 form-group text-center">
                          <button
                            className="btn btn-red"
                            type="submit"
                            name="submit"
                            disabled={formSubmitted} // Deshabilitar el botón si el formulario ya se ha enviado
                          >
                            {formSubmitted ? "Enviando..." : "Enviar"}
                          </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* Espacio derecho */}
                <div className="col-md-2"></div>
                </div>
              </div>
            </section>
            {/* End of Contact Form Section */}
        <Footer /> 
          </div>
        </body>
      </div>
    </div>
  );
};

export default SuperJobApplicationForm;
