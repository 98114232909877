import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../styles/img/logo.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Header = () => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light"
      style={{ backgroundColor: "#D90039" }}
    >
      <div className="container d-flex justify-content-between align-items-center">
        <div className="d-flex">
          <a className="navbar-brand" href="https://meetsuper.com/">
            <img src={logo} alt="Logo" />
          </a>
        </div>
        <div className="d-flex">
          <li style={{ listStyle: "none" }}>
            <NavLink
              className="nav-link text-white"
              to="/"
              exact
              activeClassName="active"
              style={{ textDecoration: "none", fontWeight: "500", fontSize: "18px", fontFamily: "'Poppins', sans-serif" }}
            >
              POSTULACIONES
            </NavLink>
          </li>
        </div>
      </div>
    </nav>
  );
};

export default Header;
